<template>
  <SetLoader v-if="!customPageDrawingEnd" />
  <template v-else>
    <template
      v-if="customPage.isMenuHidden && customPage.isSwitchCustomPagesEnabled"
    >
      <div class="custom-page-header me-2 mt-2">
        <!-- <router-link
          to="/Dashboard"
          class="btn btn-dark me-2 sx-home-link"
          @click="menuConfiguration(false)"
        >
          <i class="fa fa-home"></i>
          {{ $t("AppName", {}, { locale: this.$store.state.activeLang }) }}
        </router-link> -->
        <Dropdown
          routeName="customPage"
          requestUrl="rws-CustomPageController-UserPermittedDashboardList"
          :buttonName="customPage.name"
          buttonIcon="bi-app-indicator bi"
          buttonClasses="btn-primary"
        />
      </div>
    </template>
    <div id="custom-page-content" v-html="customPage.html"></div>
  </template>
</template>
<script>
import $ from "jquery";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: "CustomPage",
  data() {
    return {
      customPage: {},
      responseError: [],
      customPageDrawingEnd: false,
    };
  },
  components: {},
  watch: {
    "$route.params.key"() {
      if (this.$route.name === "customPage" && this.customPageDrawingEnd) {
        this.getCustomPage();
      }
    },
  },
  methods: {
    blocksClear() {
      var blocks = $(`[id*='${this.$root.bodyCustomKeys.customPage}']`);
      if (blocks) {
        blocks.remove();
      }
    },
    menuConfiguration(isHidden) {
      if (isHidden) {
        this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-enabled");
        this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-fixed");
        // this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "toolbar-fixed");
        // this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "toolbar-enabled");
        // this.$store.dispatch(
        //   Actions.REMOVE_BODY_CLASSNAME,
        //   "header-tablet-and-mobile-fixed"
        // );
        // this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "header-fixed");

        this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "aside-none");
        this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "header-none");
      } else {
        this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "header-none");
        // this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "toolbar-fixed");
        // this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "toolbar-enabled");
        // this.$store.dispatch(
        //   Actions.ADD_BODY_CLASSNAME,
        //   "header-tablet-and-mobile-fixed"
        // );
        // this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "header-fixed");
        if (this.$store.getters._isSideBarActive) {
          this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-none");
          this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "aside-enabled");
          this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "aside-fixed");
        }
      }
    },
    getCustomPage() {
      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
      this.responseError = [];
      this.customPageDrawingEnd = false;

      var formulaName = this.$route.params.key;

      if (String.isNullOrWhiteSpace(formulaName)) {
        this.$router.push(this.$appHomeUrl);
        return;
      }

      this.$appAxios
        .get(
          String.format(
            "rws-CustomPageController-Get?formulaName={0}",
            formulaName
          )
        )
        .then((response) => {
          this.customPage = response.data;
          this.$store.commit("customPageDto", this.customPage);
          this.customPageDrawingEnd = true;
          if (this.customPage.isOk) {
            var title = this.customPage.name;

            this.$root.changeDocumentTitle(title);
            setCurrentPageBreadcrumbs(this.customPage.name, [
              {
                name: this.$t(
                  "Set.CustomPage",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
              },
            ]);

            this.$root.bodyCreateBlock(
              "style",
              `${this.$root.bodyCustomKeys.customPage}_style`,
              this.customPage.css
            );
            this.$root.bodyCreateBlock(
              "script",
              `${this.$root.bodyCustomKeys.customPage}_script`,
              this.customPage.script
            );

            this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "custom-page");
            this.menuConfiguration(this.customPage.isMenuHidden);
          } else {
            this.$root.changeDocumentTitle(
              this.$t("Components.LostWave.Title"),
              {},
              { locale: this.$store.state.activeLang }
            );
            setCurrentPageBreadcrumbs(
              this.$t(
                "Components.LostWave.Title",
                {},
                { locale: this.$store.state.activeLang }
              ),
              []
            );
            this.responseError.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
  },
  mounted() {
    this.blocksClear();
    this.getCustomPage();
  },
  unmounted() {
    this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "custom-page");
  },
};
</script>
